import logo from './logo.svg';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import './Header.jsx'
import React from 'react';
import {Routes, Route, Router} from 'react-router-dom';
import Home from './Home'

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home/>} />
      </Routes>
    </>
  );
}

export default App;
