import React from 'react'
import './Res.css'
import bg1 from './img/section_3_bg_05.jpeg'



const Res = () => {

   

  return (
    <div style={{backgroundImage: ` url(${bg1})`, height:'auto' }}>
        <div className='container  ' id='reserve'>
            <div className='row center'>
                <div className='box container'>
                    <h1> Schedule a Reservation</h1>
                    <address className='add'>Hello, Please contact on email <strong ><a href='mailto:reservation@indiiflavour.pl'style={{ textDecoration: 'none', color:'black' }} >"info@indieflavours.pl"</a></strong> for reservation or contact via phone <br/> +48 576 766 995 <br/> | Please make reservation min 3 hours before | </address>
                    <button type='button' class="btn btn-outline-warning"><a href="#orderonline" style={{textDecoration:"none", color:"grey"}}>Order Online</a></button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Res