import React from 'react'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import logo from './img/fLogo.png'
import bg from './img/bg.jpg'
import menu_b from './img/menu.png'
import hflower from './img/flower-3_05.png'
import fflower from './img/menu-flower_03.png'
import signatur from './img/signa.png'
import fb from './img/fb.png'
import ig from './img/ig.png'

const Navbar = () => {
  return (
   <div>
    <BrowserView>
   <div className='row' >
        
        <div className='col col-10 bg '>
         <nav class="navbar navbar-expand-lg navbar-light " >
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      
      <li class="nav-item dropdown">
        <button class="nav-link dropdown-toggle button_reserv" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ color:"white"}}>
          Menu
        </button>
        <div class="dropdown-menu dropdown-content" aria-labelledby="navbarDropdown">
          
          <div className='drop_head_1' style={{textDecoration:'none'}}>  
           <pre>
           <a href='#story' style={{ textDecoration:'none' }}><h1  className='text_animia hline' style={{textDecoration:'none !important',}}> OUR STORY </h1></a>
           <a href='#menu' style={{ textDecoration:'none' }}><h1  className='text_animia hline'> FOOD MENU </h1></a>
           <a href='#gallary' style={{ textDecoration:'none' }}><h1  className='text_animia hline'> GALLARY </h1></a>
           </pre> 
          
          </div>

          <div className='drop_head_2 '>  
           <pre>
           <a href='#orderonline' style={{ textDecoration:'none' }}><h3 className='text_animia hline' style={{textDecoration:"none"}}> ORDER ONLINE </h3></a>
            <a href='#contact ' style={{ textDecoration:'none' }}><h3  className='text_animia hline'> CONTACT US </h3></a>
           </pre>   
          </div>

          <div className='drop_head_3'>  
           <pre>
            <h3  className='text_animia'> Location </h3>
            <address  className='text_animia'> Odrzańska 18/19, <br/> 50-113 Wrocław, Poland</address>
            
           </pre>   
          </div>

          <div className='drop_head_4'>  
           <pre>
            <h3  className='text_animia'> Hour </h3>
            <time className='text_animia'> Lunch : Monday - Friday          11:00 to 14:00 </time> 
            <br />
            <time className='text_animia'> Open at : Monday - Friday        11:00 to 22:00 </time>
            <br />
            <time className='text_animia'> Open at : Saturday  - Sunday     11:00 to 23:00 </time>
           </pre>   
          </div>


          <img src={logo} width='200' className='drop_img_1' alt="logo" />
          <img src={hflower} width='70' className='drop_img_2'alt="logo" />
            <img src={fflower} width='100' className='drop_img_3' alt="logo" />
            <img src={signatur} width='100' className='drop_img_4' alt="logo" />
          <div className='drop_img_5'>
            <img src={ig} width='30' style={{margin:30}} alt="logo" />
            <img src={fb} width='30' alt="logo" />
        </div>  
        </div>
       
      </li>
      
      <li class="nav-item">
        <button class="nav-link button_menu" ><a href='#reserve' style={{textDecoration:'none', color:'grey'}}>Reservation</a></button>
      </li>
      
    </ul>
    
  </div>
        </nav>
        </div>

        <div className='col col-2' style={{padding:20}}>
        <a class="navbar-brand text-center align-center" href="#">
            <img src={logo} width='100' alt="logo" />
        </a>
        </div>
    </div>
    </BrowserView>
    <MobileView>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img src={logo} width='50px'  alt='logo'/>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{color: 'white'}}>
    <span class=""><img src={menu_b} width="35px" alt="menyu" /></span>
  </button>

  <div class="collapse navbar-collapse " id="navbarSupportedContent" style={{padding:'10px'}}>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class=" drop_head_mob1" href="#story">Our Story </a>
      </li>
      <li class="nav-item">
        <a class=" drop_head_mob1" href="#menu">Food Menu</a>
      </li>
      <li class="nav-item">
        <a class=" drop_head_mob1" href="#gallary">Gallary</a>
      </li>
      <li class="nav-item">
        <a class=" drop_head_mob1" href="#orderonline">Order Online</a>
      </li>
      <li class="nav-item">
        <a class="drop_head_mob1" href="#contact">Contact us</a>
      </li>
      <img src={fflower} width='100' className='drop_img_3' alt="logo" /> 
    </ul>
  </div>
</nav>
  </MobileView>
  </div>
  )
}

export default Navbar