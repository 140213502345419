import React from 'react'
import logo from './img/logo.png'
import bg from './img/background.jpeg'
import landing from './img/mehu1.png'
import '../src/App.css'
import Navbar from './Navbar'
import Story from './Story'
import Menu from './Menu'
import Gallary from './Gallary'
import fb from './img/fb.png'
import ig from './img/ig.png'
import Footer from './Footer'
import Res from './Res'
import Order from './Order'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


const Home = () => {
  return (
    // navbar
    <div>
    <BrowserView>
   <div  >
     <div className=' bg'>
     <div className=' container'>
       <Navbar/>
      </div>
      
      <div className='container'>
        <div className='row'>
        <div className='col col-6 text-center items-center b1 text_ptn '>
         
         <h3>Welcome to </h3>
         <h1 >Indie Flavours</h1>
          <p>
          Reinvigorating pride in Wroclaw's multicultural background and shared heritage through seasonally-inspired food combined with an exclusive wine list.
          </p>
        </div>
        <div className='col col-6 b1'>
          <img src={landing}  alt="food" />
        </div>
        </div>
      </div>
      </div> 
      <Res/>
      <Story/>
      <Order/>
      <Menu/>
      <Gallary/>
      <footer>
       <Footer/>
      </footer>
    </div>
    </BrowserView>
    <MobileView>
    <div  >
     <div className=' bg'>
     <div className=' container'>
       <Navbar/>
      </div>
      
      <div className='container'>
        <div className='row'>
        <div className='col col-12 text-center items-center b1 text_ptn_m '>
         
         <h3>Welcome to </h3>
         <h1 >Indie Flavours</h1>
          <p>
          Reinvigorating pride in Wroclaw's multicultural background and shared heritage through seasonally-inspired food combined with an exclusive wine list.
          </p>
        </div>
        <div className='col col-12 b1'>
          <img src={landing} width='300px' alt="food" />
        </div>
        </div>
      </div>
      </div> 
      <Res/>
      <Story/>
      <Order/>
      <Menu/>
      <Gallary/>
      <footer>
       <Footer/>
      </footer>
    </div>
      
    </MobileView>
    </div>
  )
}

export default Home