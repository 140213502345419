import React, { useState } from 'react';
import delivery from './img/delivery.png';

const Order = () => {
  const [display, setDisplay] = useState('none');

  const handleClick = () => {
    if (display === 'block') {
      setDisplay('none');
      console.log('block is 1');
    } else {
      setDisplay('block');
      console.log('block is 2');
    }
  };

  return (
    <div className='justify-content' id="orderonline">
      <div className='row text-center justify-content' style={{ padding: '20px', background: '#071E26', color: 'white' }}>
        <button className='order_button' onClick={handleClick}>
          Order Online
        </button>
      </div>
      <div className='delivery row' id="btn" style={{ padding: '20px', background: '#071E26', display: display }}>
        <div className='' style={{ borderRadius: '30px' }}>
          <object data="https://www.restaurantlogin.com/api/fb/z413_z" width='100%' height='500px' id="order" style={{ boxShadow: '2px 2px 10px #E7F3E3', borderRadius: '25px' }}>
          </object>
        </div>
      </div>
    </div>
  );
}

export default Order;
