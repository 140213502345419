import React, { useState } from 'react';
import bg1 from './img/section_3_bg_05.jpeg';
import './Menu.css';
import flower from './img/flower-3_05.png';

const Menu = () => {
  const [cards] = useState({
    starter: [
      {
        title: 'POTALI SAMOSA',
        text: 'Shortcrust, Savory flaky pastry stuffed with cumin seasoned potatoes and peas, served with chutneys',
        rate: '16 Zł',
      },
      {
        title: 'VEG PAKORA',
        text: 'Indian street food, Fried spiced onion dumplings with tangy chutenys & chaat masala',
        rate: '17 Zł',
      },
      {
        title: 'PRAWN KOLIWADA',
        text: 'A bowl of delicate, spicy, deep fried crispy prawns. Beloved of Bombay families',
        rate: '40 Zł',
      },
      {
        title: 'PANEER CHILLI DRY / GRAVY',
        text: 'Cottage cheese marinated in ground spices, stir fried with peppers spring onions, in sticky soja chilli sauce.',
        rate: '33 Zł',
      },
      {
        title: 'CHILLI CHICKEN ',
        text: 'Indo-Chinese fare. A plate of crispy battered chicken breast in garlic-ginger-soy-chilli sauce. Spring onions',
        rate: '34 Zł',
      },
      {
        title: 'DESI STYLE CHICKEN LOLLYPOP ',
        text: 'Chicken wings marinated in indo-chinese spices. An Indieflavours speciality',
        rate: '37 Zł',
      },
      {
        title: 'POTALI SAMOSA',
        text: 'Shortcrust, Savory flaky pastry stuffed with cumin seasoned potatoes and peas, served with chutneys',
        rate: '16 Zł',
      },
      {
        title: 'GOBI MANCHURIAN',
        text: 'Crispy battered cauliflower cubes tossed in a spicy indo-chinese sauce',
        rate: '31 Zł',
      },
      {
        title: 'TANDOORI PANEER TIKKA',
        text: 'Cottage cheese, capsicums & onions marinated in a signature blend of chefs spices corn & pomegranate chaat. ',
        rate: '32 Zł',
      },
      {
        title: 'KASTURI CHICKEN TIKKA',
        text: 'Marinated chicken breast tandoori spices, skewered onions, bell pepper served with chutney,',
        rate: '33 Zł',
      },
      {
        title: 'TRIO OF CHICKEN TIKKA',
        text: 'A combination of three delicious flavours - Chicken tikka, Malai Chicken Tikka, Hariyali ChickenTikka',
        rate: '38 Zł',
      },
      {
        title: 'KING PRAWN WITH MANGO & CUCUMBER SALSA',
        text: 'Juicy king prawns marinated in mint,coriander, lemon juice & crushed red chillies.',
        rate: '49 Zł',
      },
      {
        title: 'FISH TIKKA',
        text: 'Catch of the day fish marinated in our homemade spice and lemon chutney and cooked in the tandoor ',
        rate: '35 Zł',
      },
      {
        title: 'SPICY LAMB CHOPS',
        text: 'Two chops of generous proportions. Laid overnight in special tandoori marinade of lime juice, charred slightly at the edges, succulent and simple by the grill.',
        rate: '17 Zł',
      },
      {
        title: 'SALMON TIKKI',
        text: 'Salmon fillet marinated in lemon juice & and tandoori spices. ',
        rate: '48 Zł',
      },
      // Add more cards for Starter category
    ],
   
    mainCourse: [
      {
        title: 'PANEER MAKNANI',
        text: 'Cottage cheese cubes cooked in a rich creamy tomato gravy with butter',
        rate: '34 Zł',
      },
      {
        title: 'PANEER TIKKA MASALA',
        text: 'Fresh and soft cottage cheese tikka cooked with bell peppers in an onion and tomato based gravy.',
        rate: '34 Zł',
      },
      {
        title: 'DAL MAKHANI',
        text: 'Assortedlentils, tomatoes and spices, slow cooked for hours, finished with a dollop of ghee and cream',
        rate: '28 Zł',
      },
      {
        title: 'DOUBLE KA DAL TADKA',
        text: 'Split yellow lentils tempered with cumin, garlic and red chillies',
        rate: '28 Zł',
      },
      {
        title: 'KADHAI PANEER',
        text: 'Tender cubes of paneer tossed with capsicum, coriander and tomatoes in onion tomato based gravy with hint of fenugreek leaves.',
        rate: '34 Zł',
      },
      {
        title: 'MUTTER PANEER',
        text: 'Peas and paneer in a tangy onion, tomato and turmeric yellow curry',
        rate: '33 Zł',
      },
      {
        title: 'JEERA ALOO',
        text: 'Tender baby potatoes, with cumin seeds & pickling spices',
        rate: '30 Zł',
      },
      {
        title: 'PUNJABI CHANA MASALA',
        text: 'Chickpeas soaked in an infusion of tea leaves and with our in-house pounded masalas, flavoured with crushed pomegranate powder',
        rate: '32 Zł',
      },
      {
        title: 'PALAK PANEER',
        text: 'Paneer in a smooth, creamy and delicious spinach gravy',
        rate: '34 Zł',
      },
      {
        title: 'BUTTER CHICKEN ',
        text: 'Classical chicken tikka, simmered in our signature tomato, cashew, butter gravy and accentuated with dry fenugreek',
        rate: '36 Zł',
      },
      {
        title: 'MURG TIKKA MASALA',
        text: 'Creamy chicken tikka laced with mixed bell peppers and onions with mildly spiced gravy.',
        rate: '37 Zł',
      },
      {
        title: 'METHI CHICKEN ',
        text: 'Tandoor chicken cooked with caramelised tomatoes with fenugreek, spicy & intense flavour',
        rate: '35 Zł',
      },
      {
        title: 'CHICKEN VINDALOO',
        text: 'A piquant Goan delicacy flavoured with vinegar soaked chillies and cooked in a smooth gravy',
        rate: '38 Zł',
      },
      {
        title: 'CHICKEN MADRAS',
        text: 'A specialty from south India with roasted coconut curry and black mustard',
        rate: '36 Zł',
      },
      {
        title: 'CHICKEN KADHAI',
        text: 'Boneless chicken & diced green/red peppers tossed in thick onion, tomato, ginger-garlic curry',
        rate: '35 Zł',
      },
      {
        title: 'LAMB SHANK MASALA',
        text: 'Slow-cooked Lamb shank infused with aromatic spices, drizzled with robust sauce scented with Nutmeg, Mace, Cinnamon, Bay Leaf.',
        rate: '49 Zł',
      },
      {
        title: 'LAMB MASALA',
        text: 'Boneless lamb, marinated and slow-cooked in our secret spice blend and tomato-onion gravy',
        rate: '46 Zł',
      },
      {
        title: 'LAMB KORMA',
        text: 'Slow-cooked boneless pieces lamb infused with aromatic spices, drizzled with in cashew onion curry',
        rate: '47 Zł',
      },
      {
        title: 'KERALA PRAWN CURRY',
        text: 'Juicy shrimps simmered in a smooth, coconut, tomatoes and tamarind sauce, subtly-flavoured and aromatic gravy',
        rate: '48 Zł',
      },
      // Add more cards for Main Course category
    ],
    bread: [
      {
        title: 'PLAIN / BUTTER NAAN',
        text: 'Traditionally made with refined flour and baked in our Tandoor.Option : Naan with drizzling butter. ',
        rate: '9 / 11 Zł',
      },
      {
        title: 'GARLIC AND CORIANDER NAAN ',
        text: 'Naan with minced garlic and coriander sprinkle.',
        rate: '13 Zł',
      },
      {
        title: 'TANDOORI ROTI',
        text: 'Whole wheat Indian bread cooked on the sides in our Tandoor ',
        rate: '9 Zł',
      },
      {
        title: 'ALOO PARATA',
        text: 'Tandoor baked naan poatato filled with our inhouse secret spice mix',
        rate: '16 Zł',
      },
      // Add more cards for Main Course category
    ],
    rice: [
      {
        title: 'PLAIN BASMATI RICE',
        text: 'The fragrant long grain basmati rice.',
        rate: '8 Zł',
      },
      {
        title: 'BASMATI JEERA RICE',
        text: 'The fragrant one lightly seasoned with cumin and coriander.',
        rate: '12 Zł',
      },
      {
        title: 'VEG PULAV',
        text: 'Aromatic long-grain basmati rice infused with delicately balanced whole spices , garam masala and vegetable.',
        rate: '20 Zł',
      },
      // Add more cards for Main Course category
    ],
    clasic: [
      {
        title: 'FRIED PAPAD / MASALA PAPAD',
        text: 'Thin Indian crispy cracker or flat bread served fried. Masala Papad topped with chopped onions, peppers, chutney’s or homemade condiments.',
        rate: '12 Zł',
      },
      {
        title: 'POMEGRANATE MINT RAITA',
        text: 'Yoghurt flavoured with mint, coriander and pomegranate',
        rate: '12 Zł',
      },
      {
        title: 'KACHUMBER',
        text: 'Sliced Cucumber, Tomato, mint, coriander and pomegranate',
        rate: '9 Zł',
      },
      // Add more cards for Main Course category
    ],
    sweet: [
      {
        title: 'GULAB JAMUN',
        text: 'Cheese dumplings simmered in sugar syrup sauce and garnished with pistachios.',
        rate: '14 Zł',
      },
      {
        title: 'CHOCOLATE LAVA CAKE WITH MANGO TWIST',
        text: 'Delicious chocolate cupcakes with rich molten chocolate center with Mango Ice cream. ',
        rate: '15 Zł',
      },
      {
        title: 'CARDAMOM & BASMATI RICE KHEER',
        text: 'Creamy rice dessert delicately flavoured with cardamom and garnished with pistachio.',
        rate: '16 Zł',
      },
      // Add more cards for Main Course category
    ],
    drink: [
      {
        title: 'MASALA CHAI',
        text: 'Rich blend of spices with black tea, finished with milk',
        rate: '14 Zł',
      },
      {
        title: 'FRESH MINT TEA',
        text: 'Fragrant, fresh mint tee, naturally caffeine free',
        rate: '14 Zł',
      },
      {
        title: 'FRESH GINGER TEA WITH HONEY',
        text: 'Delightfully floral with a natural honey note.',
        rate: '14 Zł',
      },
      {
        title: 'ASSORTED TEA',
        text: 'English Breakfast, Earl Grey, Fruit tea, Green tea, Camomile, ',
        rate: '14 Zł',
      },
      {
        title: 'SWEET LASSI | MANGO LASSI',
        text: 'Fresh yoghurt, saffron with or without mango syrup',
        rate: '14 Zł',
      },
      {
        title: 'SALTY LASSI',
        text: 'Lassi made with fresh yoghurt. Rock salt & roasted cumin ',
        rate: '12 Zł',
      },
     
      
      // Add more cards for Main Course category
    ],
    bar: [
      {
        title: 'SHAKHARI (VEG)',
        text: 'Fresh seasonal vegetables cooked in traditional Dum technique, Biryani rice, rose water, whole spices, yoghurt, coriander, ghee',
        rate: '35 Zł',
      },
      {
        title: 'MASAHARI (MEAT)',
        text: 'Fresh local meat cooked in traditional Dum technique, Biryani rice, rose water, whole spices, yoghurt, coriander, ghee | Lamb / Prawns ',
        rate: '40 Zł',
      },
      // Add more cards for Main Course category
    ],
    salat: [
      {
        title: 'MANGO TIKKA SALAD',
        text: 'Thin mango slices, red onion, cucumber, topped with mango dressing. ',
        rate: '32 Zł',
      },
      {
        title: 'KUTCHOOMER SALAD',
        text: 'An invigorating combination of diced apple,red onion, tomato & cucumber. Dressed in lemon juice and black pepper.',
        rate: '32 Zł',
      },
      // Add more cards for Main Course category
    ],
    // Add more categories with their respective cards
  });

  return (
    <div id="menu">
      <div style={{ background: '#071e26' }}>
        <div className="container text-center justify-content" style={{ padding: '50px', color: 'white' }}>
          <h1>Dear Guests — Welcome!</h1>
          <h4>Indieflavours serves aromas of the nation, now on your plate.</h4>
          <p>
            The ingredients are at the center of Indiekitchen’s cuisine, in that sense the relationship with them is
            fundamental. intrinsic natural flavors of local produce and delicately urges each ingredient to show its
            potential. Most of the ingredients are from India. Most of the ingredients are local, Pujol works for example
            with Gaia, a network of farmers who cultivate under the principles of regenerative agriculture. The result of
            this care is high-quality ingredients whose flavors are enhanced in the kitchen. amplify the essential flavors,
            evokes antique flavors The aim is to express Indian flavors with a personal touch. Furthermore, with FairMast
            chicken breasts, we also provide for better animal welfare in accordance to the standards of the Initiative
            Tierwohl and also the German Animal Welfare Federation – so that you can enjoy your meal with us with an even
            better peace of mind. a very welcoming place; and an informative, attentive and professional customer service.
            We consider acting responsibly and environmentally friendly, and being committed to social justice to be simply
            part of the equation. We are reducing our ecological footprint in all aspects of our actions and we stand for
            dealing with people and animals with respect. And that is our contribution to a more sustainable, healthier,
            and fairer society. You would like to learn more about our philosophy? Please scan the QR code for more
            information.
          </p>
          <img src={flower} alt="img" className="flower" />
        </div>
      </div>

      <div className="row" style={{ backgroundImage: `url(${bg1})`, height: 'auto' }}>
        <div className="container text-center items-center" style={{ padding: '50px' }}>
          <h1>OUR MENU</h1>
          <ul className="nav nav-tabs justify-content-center">
            <li className="nav-item">
              <a className="nav-link active btn_tag" data-toggle="tab" href="#home">Starter</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu1">Main Course</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu2">Indian Bread</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu3">Rice</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu4">Indie Flavour classics</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu5">Sweet Pleasure</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu6">Drink</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu7">Biryani Culture</a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn_tag" data-toggle="tab" href="#menu8">Salat Bowls</a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane container active" id="home">
              <div className="cards">
                {cards.starter.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu1">
              <div className="cards">
                {cards.mainCourse.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu2">
              <div className="cards">
                {cards.bread.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu3">
              <div className="cards">
                {cards.rice.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu4">
              <div className="cards">
                {cards.clasic.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu5">
              <div className="cards">
                {cards.sweet.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu6">
              <div className="cards">
                {cards.drink.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu7">
              <div className="cards">
                {cards.bar.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
            <div className="tab-pane container fade" id="menu8">
              <div className="cards">
                {cards.salat.map((card, i) => (
                  <div key={i} className="card">
                    <h3>{card.title}</h3>
                    <p>{card.text}</p>
                    <h5>{card.rate}</h5>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
