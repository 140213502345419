import React from 'react'
import img1 from './img/GALLARY_1.jpeg'
import img2 from './img/GALLARY_5.jpeg'
import img3 from './img/GALLARY_6.jpeg'
import img4 from './img/GALLARY_4.jpeg'
import img5 from './img/GALLARY_3.jpeg'
import img6 from './img/GALLARY_7.jpeg'
import bg1 from './img/img_4.jpeg'


const Gallary = () => {
  return (
    <div id="gallary"> 
       
   <div className='text-center ' style={{padding:"0px", background:"black"}}>
    <div id="demo" class="carousel slide " data-ride="carousel">


<ul class="carousel-indicators">
  <li data-target="#demo" data-slide-to="0" class="active"></li>
  <li data-target="#demo" data-slide-to="1"></li>
  <li data-target="#demo" data-slide-to="2"></li>
</ul>


<div class="carousel-inner">
  <div class="carousel-item active">
    <img src={img1} width="80%" alt="Los Angeles"/>
  </div>
  <div class="carousel-item">
    <img src={img2} width="80%" alt="Chicago"/>
  </div>
  <div class="carousel-item">
    <img src={img3} width="80%" alt="New York"/>
  </div>
  <div class="carousel-item">
    <img src={img4} width="80%" alt="New York"/>
  </div>
  <div class="carousel-item">
    <img src={img5} width="80%" alt="New York"/>
  </div>
  <div class="carousel-item">
    <img src={img6} width="80%" alt="New York"/>
  </div>
</div>


<a class="carousel-control-prev" href="#demo" data-slide="prev" style={{ background:'#4F747D' }}>
  <span class="carousel-control-prev-icon"></span>
</a>
<a class="carousel-control-next" href="#demo" data-slide="next" style={{ background:'#4F747D' }}>
  <span class="carousel-control-next-icon"></span>
</a>

</div>
    
    </div>
    </div>
  )
}

export default Gallary