import React from 'react'
import { MDBFooter, MDBContainer } from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <MDBFooter className='text-center text-white' style={{ backgroundColor: '#CA7D57' }}>
        <div className='container text-center' style={{padding:"50px"}}>
  
<section class="mb-4" id="contact">

  
    <h2 class="h1-responsive font-weight-bold text-center my-4">Contact us</h2>
  
    <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
        a matter of hours to help you.</p>

    <div class="row">

        
        <div class="col-md-9 mb-md-0 mb-5">
            <form id="contact-form" name="contact-form" action="mail.php" method="POST">

                <div class="row">

                 
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input type="text" id="name" name="name" class="form-control"/>
                            <label for="name" class="">Your name</label>
                        </div>
                    </div>
                   
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input type="text" id="email" name="email" class="form-control"/>
                            <label for="email" class="">Your email</label>
                        </div>
                    </div>
                  

                </div>
               
                <div class="row">
                    <div class="col-md-12">
                        <div class="md-form mb-0">
                            <input type="text" id="subject" name="subject" class="form-control"/>
                            <label for="subject" class="">Subject</label>
                        </div>
                    </div>
                </div>
               
                <div class="row">

                    <div class="col-md-12">

                        <div class="md-form">
                            <textarea type="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                            <label for="message">Your message</label>
                        </div>

                    </div>
                </div>
               

            </form>

            <div class="text-center text-md-left">
                <a class="btn btn-primary" onclick="document.getElementById('contact-form').submit();">Send</a>
            </div>
            <div class="status"></div>
        </div>
       
        <div class="col-md-3 text-center">
            <ul class="list-unstyled mb-0">
                <li><i class="fas fa-map-marker-alt fa-2x"></i>
                    <p>Odrzańska 18/19, 50-113 Wrocław</p>
                </li>

                <li><i class="fas fa-phone mt-4 fa-2x"></i>
                    <p>+48 576 766 995</p>
                </li>

                <li><i class="fas fa-envelope mt-4 fa-2x"></i>
                    <p>info@indieflavours.pl</p>
                </li>
            </ul>
        </div>
        

    </div>

</section>

        </div>
      <MDBContainer className='p-4'></MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: '#4F747D' }}>
       
        <a className='text-white' >
        ©Indieflavours 2023 | All right are reserved |  privacy policy 
        </a>
        
       <br/>
        <a className='text-white' style={{ textDecoration:'none' }} href='https://scopewit.com/'>
        Design by Scopewit Sp.zo.o
        </a>
      </div>
     
    </MDBFooter>
  )
}

export default Footer