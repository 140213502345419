import React from 'react'
import image1 from './img/indiiflav.jpeg'
import image2 from './img/section_1_flower2.png'
import Order from './Order'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Story = () => {
  return (
   <div>
    <BrowserView>
   <div>
        <div className=''>
            <div className='row' id="story">
                <div className='col col-6' style={{backgroundImage: ` url(${image1})`, height:'658px'}}>
                   
                </div>
                <div className='col col-6 text-center items-center' style={{ background:'#E7F3E3', padding:'60px' }}>
                    <h5 className=''> OUR STORY</h5> <br/>
                    <h3> Driven to provide a dining experience unlike any other, <strong>Indie Flavour</strong> cuisine reflects a harmonious balance between classic cooking techniques and contemporary recipes</h3><br/>
                    <p> Whether a memorable dinner; handcrafted cocktail or a glass of carefully curated wine, Aiana delivers distinctly Canadian cuisine and impeccable hospitality – all from the pulse of Ottawa’s bustling downtown.</p>  
                  
                    <img src={image2} alt='image' className='image2_2section'/>
                </div>
            </div>
        </div>
    </div>
    </BrowserView>
    <MobileView>
    <div>
        <div className=''>
            <div className='row' id="story">
                <div className='col col-12' style={{backgroundImage: ` url(${image1})`, height:'658px'}}>
                   
                </div>
                <div className='col col-12 text-center items-center' style={{ background:'#E7F3E3', padding:'60px' }}>
                    <h5 className=''> OUR STORY</h5> <br/>
                    <h3> Driven to provide a dining experience unlike any other, <strong>Indie Flavour</strong> cuisine reflects a harmonious balance between classic cooking techniques and contemporary recipes</h3><br/>
                    <p> Whether a memorable dinner; handcrafted cocktail or a glass of carefully curated wine, Aiana delivers distinctly Canadian cuisine and impeccable hospitality – all from the pulse of Ottawa’s bustling downtown.</p>  
                  
                    <img src={image2}  alt='image' className='image2_2section'/>
                </div>
            </div>
        </div>
    </div>
    </MobileView>
    </div>
  )
}

export default Story